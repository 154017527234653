import React, { useState, useEffect } from "react";
import {
	EuiAvatar,
	EuiCollapsibleNav,
	EuiFlexItem,
	EuiFlyout,
	EuiFlyoutBody,
	EuiFlyoutHeader,
	EuiHeader,
	EuiHeaderSectionItemButton,
	EuiIcon,
	EuiPopover,
	EuiPortal,
	EuiShowFor,
	EuiText,
	EuiTitle,
	EuiSelectableTemplateSitewide,
	EuiSelectableMessage,
	EuiConfirmModal,
	EuiContextMenu,
	EuiOverlayMask,
	EuiLoadingSpinner,
} from "@elastic/eui";
import logo from "./../assets/images/logo/logo.svg";
import { EuiImage } from "@elastic/eui";
import NavBar from "./NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth-service";
import settingService from "../services/setting-service";

export default () => {
	const [isLogOut, setIsLogOut] = useState(false);

	let destroyModal;
	const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const closeDestroyModal = () => setIsDestroyModalVisible(false);
	const showDestroyModal = () => setIsDestroyModalVisible(true);

	function logout() {
		AuthService.logout();
		setIsLogOut(true);

		setTimeout(function () {
			setIsLogOut(false);
			navigate("/login");
		}, 3000);
	}

	const tiltleBreadCrum = {
		kycs: "Compliance",
		merchants: "Merchants",
		cashouts: "Cashouts",
		finance: "Finance",
		setting: "Settings",
		serviceAccount: "System settings",
		deposits:"Deposits",
		"partner-portal":"Partner Portal"
	};

	const modal = (
		<>
			<EuiOverlayMask>
				<EuiLoadingSpinner size="xxl" />
			</EuiOverlayMask>
		</>
	);

	if (isDestroyModalVisible) {
		destroyModal = (
			<EuiConfirmModal
				title="Logout"
				onCancel={closeDestroyModal}
				onConfirm={logout}
				cancelButtonText="No, don't do it"
				confirmButtonText="Yes, do it"
				buttonColor="danger"
				defaultFocusedButton="confirm"
			>
				<p>Are you sure you want to do this?</p>
			</EuiConfirmModal>
		);
	} 

	useEffect(() => {
		document.body.classList.add("euiBody--headerIsFixed--double");

		settingService.me().then(res => console.log(res))

		return () => {
			document.body.classList.remove("euiBody--headerIsFixed--double");
		};
	}, []);

	/**
	 * Collapsible Nav
	 */
	const [navIsOpen, setNavIsOpen] = useState(
		JSON.parse(String(localStorage.getItem("navIsDocked"))) || false
	);
	const [navIsDocked, setNavIsDocked] = useState(
		JSON.parse(String(localStorage.getItem("navIsDocked"))) || false
	);
	const collapsibleNav = (
		<EuiCollapsibleNav
			aria-label="Main navigation"
			isOpen={navIsOpen}
			isDocked={navIsDocked}
			button={
				<EuiHeaderSectionItemButton
					aria-label="Toggle main navigation"
					onClick={() => setNavIsOpen(!navIsOpen)}
				>
					<EuiIcon type={"menu"} size="m" aria-hidden="true" />
				</EuiHeaderSectionItemButton>
			}
			onClose={() => setNavIsOpen(false)}
		>
			<EuiFlexItem className="eui-yScroll">
				{/* Docs callout */}
				<NavBar />
			</EuiFlexItem>
		</EuiCollapsibleNav>
	);

	/**
	 * Header Alerts
	 */
	const [isAlertFlyoutVisible, setIsAlertFlyoutVisible] = useState(false);
	const headerAlerts = (
		<EuiPortal>
			<EuiFlyout onClose={() => setIsAlertFlyoutVisible(false)} size="s">
				<EuiFlyoutHeader hasBorder>
					<EuiTitle size="s">
						<h2>What's new at Payunit</h2>
					</EuiTitle>
				</EuiFlyoutHeader>
				<EuiFlyoutBody>
					<EuiText size="s" color="subdued">
						<p>Feature is not available for the moment</p>
					</EuiText>
				</EuiFlyoutBody>
			</EuiFlyout>
		</EuiPortal>
	);

	/**
	 * User Menu
	 */
	const panels = [
		{
			id: 0,
			paddingSize: "none",
			title: "Admin",
			classeName: "user-context",
			items: [
				{
					name: "Edit profile",
					icon: "user",
					onClick: () => {
						navigate("/setting/profile");
					},
				},
				{
					name: "Setting",
					icon: "gear",
					onClick: () => {
						navigate("/setting/users");
					},
				},
				{
					id: 2,
					name: "Log out",
					icon: "exit",
					onClick: () => {
						showDestroyModal();
					},
				},
			],
		},
	];
	const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
	const userMenu = (
		<EuiPopover
			repositionOnScroll
			button={
				<EuiHeaderSectionItemButton
					aria-expanded={isUserMenuVisible}
					aria-haspopup="true"
					aria-label="User menu"
					onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
				>
					<EuiAvatar name="Admin" size="s" />
				</EuiHeaderSectionItemButton>
			}
			isOpen={isUserMenuVisible}
			anchorPosition="downRight"
			closePopover={() => setIsUserMenuVisible(false)}
		>
			<div>
				<EuiContextMenu
					initialPanelId={0}
					paddingSize={"none"}
					panels={panels}
				/>
			</div>
		</EuiPopover>
	);

	/**
	 * Sitewide search
	 */
	const search = (
		<EuiSelectableTemplateSitewide
			options={[]}
			searchProps={{
				append: "⌘K",
				compressed: true,
				placeholder: "Quick Search",
			}}
			popoverButton={
				<EuiHeaderSectionItemButton aria-label="Sitewide search">
					<EuiIcon type="search" size="m" />
				</EuiHeaderSectionItemButton>
			}
			popoverButtonBreakpoints={["xs", "s"]}
			popoverProps={{
				repositionOnScroll: true, // Necessary when placing search in a fixed component
			}}
			emptyMessage={
				<EuiSelectableMessage style={{ minHeight: 300 }}>
					<p>feature in not available for the moment</p>
				</EuiSelectableMessage>
			}
		/>
	);

	return (
		<>
			{destroyModal}
			<EuiHeader
				theme="dark"
				position="fixed"
				sections={[
					{
						items: [
							<>
								<EuiImage size={70} alt={"payunit"} src={logo} />
							</>,
						],
						borders: "none",
					},
					{
						items: [<EuiShowFor sizes={["m", "l", "xl"]}>{search}</EuiShowFor>],
						borders: "none",
					},
					{
						items: [
							<EuiShowFor sizes={["xs", "s"]}>{search}</EuiShowFor>,
							<EuiHeaderSectionItemButton
								notification={false}
								aria-label="Notifications: Updates available"
								onClick={() => setIsAlertFlyoutVisible(!isAlertFlyoutVisible)}
							>
								<EuiIcon type="cheer" size="m" />
							</EuiHeaderSectionItemButton>,
							userMenu,
						],
						borders: "none",
					},
				]}
			/>
			<EuiHeader
				position="fixed"
				sections={[
					{
						items: [collapsibleNav],
						breadcrumbs: [
							{
								text: "Dashboard",
								onClick: () => {
									navigate("/");
								},
							},
							{
								text: tiltleBreadCrum[location.pathname.split("/")[1]],
							},
						].filter((item) => item.text),
						borders: "right",
					},
				]}
			/>
			{isLogOut && modal}

			{isAlertFlyoutVisible ? headerAlerts : null}
		</>
	);
};
