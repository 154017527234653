import { Navigate } from "react-router-dom";
import { VerifyAuth } from "./verifyAuth";

export const ProtectedRoute = ({ redirectPath = "/login", children }) => {
	if (!VerifyAuth()) {
		console.log(VerifyAuth())
		return <Navigate to={redirectPath} replace />;
	}

	return children;
};
