import React from "react";
import { EuiCollapsibleNavGroup, EuiPinnableListGroup } from "@elastic/eui";
import { useLocation, useNavigate } from "react-router-dom";

export const TopNavLinks = [
	{
		label: "Home",
		iconType: "home",
		isActive: true,
		pinnable: false,
	},
];

export const UserNavLinks = [{ label: "List Users" }];

export const DeploymentsGroup = (
	<EuiCollapsibleNavGroup
		title={
			<span>
				<strong></strong>
			</span>
		}
		iconSize="xl"
		isCollapsible={false}
		initialIsOpen={false}
		background="dark"
	></EuiCollapsibleNavGroup>
);

const NavBar = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const LinksKycs = [
		// { label: 'Overview', isActive: location.pathname.indexOf('overview') > 0, pinnable: false, className: 'pnter-select', onClick: () => navigate('overview') },
		// { 
		// 	label: 'create KYCs', 
		// 	isActive: location.pathname.indexOf('create') > 0, 
		// 	pinnable: false, 
		// 	className: 'pnter-select', 
		// 	onClick: () => navigate('create') 
		// },
		{
			label: "List Kycs",
			isActive: location.pathname.indexOf("list") > 0,
			pinnable: false,
			className: "pnter-select",
			onClick: () => navigate("kycs/list"),
		},
	];
	const LinksCashouts = [
		// {
		//     label: 'Overview' ,
		//     pinnable: false ,
		//     className : 'pnter-select' ,
		//     isActive : location.pathname.indexOf('overview') > 0 ,
		//     onClick : ()=>{ navigate('/cashouts/overview') }
		// },
		{
			label: "List cashouts",
			isActive: location.pathname.indexOf("list") > 0,
			pinnable: false,
			className: "pnter-select",
			onClick: () => navigate("/cashouts/list"),
		},
	];

	
	const LinksDeposits = [
		{
			label: "List deposits",
			isActive: location.pathname.indexOf("list") > 0,
			pinnable: false,
			className: "pnter-select",
			onClick: () => navigate("/deposits/list"),
		},
	];

	const LinksFinance = [
		{
			label: "Transactions",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("transactions") > 0,
			onClick: () => {
				navigate("/finance/transactions");
			},
		},
		{
			label: "Applications",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("applications") > 0,
			onClick: () => {
				navigate("/finance/applications");
			},
		},
	];

	const LinksServiceAccount = [
		{
			label: "List service acc",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("serviceAccount/list") > 0,
			onClick: () => {
				navigate("/serviceAccount/list");
			},
		},
		{
			label: "Application Profile",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("serviceAccount/appProfile") > 0,
			onClick: () => {
				navigate("/serviceAccount/appProfile/list");
			},
		},
		{
			label: "payement service management",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("listServiceAcc") > 0,
			onClick: () => {
				navigate("#");
			},
		},
	];

	const LinksMerchant = [
		{
			label: "Overview",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("overview") > 0,
			onClick: () => {
				navigate("/merchants/overview");
			},
		},
		{
			label: "Merchants",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("list") > 0,
			onClick: () => {
				navigate("/merchants/list");
			},
		},
		{
			label: "PayOuts",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("payouts") > 0,
			onClick: () => {
				navigate("/merchants/payouts");
			},
		},
		{
			label: "Applications",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("applications") > 0,
			onClick: () => {
				navigate("/merchants/applications");
			},
		},
		// {
		//     label: 'Transactions',
		//     pinnable: false,
		//     className: 'pnter-select',
		//     isActive: location.pathname.indexOf('transactions') > 0,
		//     onClick: () => { navigate('/merchants/transactions') },
		// },
	];

	const LinksPaymentPortal = [
		{
			label: "Partners",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("partners") > 0,
			onClick: () => {
				navigate("/partner-portal/partners");
			},
		},
		{
			label: "Kycs",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("kycs") > 0,
			onClick: () => {
				navigate("/partner-portal/kycs");
			},
		},
		{
			label: "Apis",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("apis") > 0,
			onClick: () => {
				navigate("/partner-portal/apis");
			},
		},
		{
			label: "Pricing",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("pricing") > 0,
			onClick: () => {
				navigate("/partner-portal/pricing");
			},
			
		},
		{
			label: "Billing",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("billing") > 0,
			onClick: () => {
				navigate("/partner-portal/billing");
			},
		},
		{
			label: "Transaction",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("transaction") > 0,
			onClick: () => {
				navigate("/partner-portal/transaction");
			},
		},
		{
			label: "Logs",
			pinnable: false,
			className: "pnter-select",
			isActive: location.pathname.indexOf("logs") > 0,
			onClick: () => {
				navigate("/partner-portal/logs");
			},
		},
	];

	return (
		<>
			{DeploymentsGroup}
			<EuiCollapsibleNavGroup background="light">
				<EuiPinnableListGroup
					listItems={TopNavLinks}
					onPinClick={() => {}}
					maxWidth="none"
					color="text"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>

			{/* compliance */}
			<EuiCollapsibleNavGroup
				title="Compliance"
				iconType="notebookApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksKycs}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>

			{/* merchant */}
			<EuiCollapsibleNavGroup
				title="Merchants"
				iconType="usersRolesApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksMerchant}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>

			{/* finance */}
			<EuiCollapsibleNavGroup
				title="Finances"
				iconType="visualizeApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksFinance}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>
			

			{/* Deposits */}
			<EuiCollapsibleNavGroup
				title="Deposits"
				iconType="agentApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksDeposits}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
					
				/>
			</EuiCollapsibleNavGroup>

			{/* cashouts */}
			<EuiCollapsibleNavGroup
				title="Cashouts"
				iconType="reportingApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksCashouts}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
					
				/>
			</EuiCollapsibleNavGroup>

			{/* payment portal */}
			<EuiCollapsibleNavGroup
				title="Partner Portal"
				iconType="reportingApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksPaymentPortal}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>

			{/* Service account */}
			<EuiCollapsibleNavGroup
				title="System settings"
				iconType="managementApp"
				isCollapsible={true}
				initialIsOpen={false}
			>
				<EuiPinnableListGroup
					listItems={LinksServiceAccount}
					onPinClick={() => {}}
					maxWidth="none"
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>
		</>
	);
};


export default NavBar