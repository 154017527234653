import Axios from "axios";
import { BASE_API_URL } from ".";

const axios = Axios.create({
    baseURL: `${BASE_API_URL}`
})

axios.interceptors.request.use((request) => {
    const token = JSON.parse(localStorage.getItem('user'))?.auth?.token
    request.headers.Authorization = `Bearer ${token}`
    return request
}, (error) => {
    return Promise.reject(error)
})
axios.interceptors.response.use((response)=>{
    return response
},(error)=>{
    if(error.response.data.message === 'INVALID TOKEN'){
        window.location.href="/login"
    }
    return Promise.reject(error)
})

export default axios 