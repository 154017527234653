
export function VerifyAuth() {

    const parseJwt = (token) => {
        try {
            return JSON.parse(window.atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
        const decodedJwt = parseJwt(user?.auth?.token);
        if (decodedJwt?.exp * 40000 < Date.now()) return false;
    }
    else  return false;

    return true;

}