import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL

class AuthService {
  async login(email, password) {
    return axios
      .post(API_URL + "users/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data?.data?.auth?.token) {
          localStorage.setItem("user", JSON.stringify(response.data?.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

 
}

export default new AuthService();