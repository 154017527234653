import axios from "../config/axios-config"

export default {
	addUser(data) {
		return axios.post("users/register", data);
	},
	getUsers() {
		return axios.get("users");
	},
	getSingleUser(id) {
		return axios.get(`users/${id}`);
	},
	updateCurrentUser(data) {
		return axios.put("users/update", data);
	},
	updateUserPassword(data) {
		return axios.post("users/change-password", data);
	},
	me() {
		return axios.get("users/get/me");
	}
};
